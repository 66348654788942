@import '../../../styles/marketplace.css';

.howitworks {
  display: flex;
  padding: 60px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background: #dfedf7;

  @media (--viewportLarge) {
    padding: 96px 0px;
  }
}

.howitworks_label {
  padding: 8px 24px;
  border-radius: 40px;
  background: #fff;
  backdrop-filter: blur(2px);
  color: #313131;
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 30px; /* 150% */
  }
}

.howitworks_title {
  color: #1c1c1c;
  text-align: center;

  /* ProLed 32 Heading */
  font-family: 'Bricolage Grotesque';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 137.5% */
  letter-spacing: -0.64px;
  margin: 0;

  @media (--viewportLarge) {
    width: 387px;
  }
}
