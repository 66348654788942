@import '../../styles/marketplace.css';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoContainer {
  display: flex;
  margin-left: 16px;
  align-items: center;
  padding: 16px 0px;
  @media (--viewportMedium) {
    margin-left: 24px;
  }
}

.buttonsContainer {
  display: flex;
}

.buttonContainer {
  padding: 16px;

  @media (--viewportMedium) {
    padding: 16px 24px;
  }
}

.button {
  text-decoration: none;
  color: #0f69e0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.disabledButton {
  opacity: 0.3;
  cursor: not-allowed;
}

.statusContainer {
  width: 100px;

  @media (--viewportMedium) {
    width: 152px;
  }
}

.status {
  padding: 4px 8px;
  background-color: #e9e9e9;
  border-radius: 16px;
  font-size: 14px;
  line-height: 20px;
  height: fit-content;
  color: #1c1c1c;
  width: max-content;

  @media (--viewportMedium) {
    font-size: 16px;
    padding: 2px 12px;
    line-height: 24px;
  }
}

.image {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    width: 44px;
    height: 44px;
  }
}

.listingTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600px;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.listingCategory {
  font-size: 14px;
  line-height: 20px;
  color: #656565;
}

.activeStatus {
  color: #254d6e;
  background-color: #dfedf7;
}

.tooltipButton {
  position: relative;
}

.tooltiptext {
  opacity: 0;
  visibility: hidden;
  width: 185px;
  background-color: #313131;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 5;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
}

.tooltipButton:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
}
