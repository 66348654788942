@import '../../styles/marketplace.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */

/* .root {
  margin-top: 100px;
} */

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: -2px;
    margin-bottom: 33px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
    margin-left: 6px;
  }
}

.bookingTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 9px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Mobile phones introduce bottom-bar, which takes 102px vertical space.
     In addition to that we have fixed button that takes 84px vertical space.
  */
  margin: 0 0 186px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0;
  width: 100%;
  bottom: 0;
  margin-top: 40px;
  background-color: var(--matterColorLight);
  margin-bottom: 1rem;

  @media (--viewportMedium) {
    padding: 0 24px 24px 24px;
    padding: 0;
    margin-top: 44px;
    position: static;
    bottom: unset;
    background-color: transparent;
    width: 424px;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 101;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px 40px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    /* display: none; */
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  color: #1c1c1c;
  font-size: 24px;
  line-height: 32px;
}

.perUnit {
  color: #7a7a7a;
  font-size: 18px;
  line-height: 30px;
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;
  height: 40px;
  width: 104px;
  font-size: 16px;
  padding: 8px 16px;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.contactWrapper {
  max-width: 424px;
}

.contactInfo {
  font-size: 14px;
}

.contactLink {
  @apply --marketplaceButtonStyles;
  margin-bottom: 50px;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.contactError {
  color: red;
  text-align: center;
}

.button {
  border-radius: 8px;
  border: none;
  margin-bottom: 50px;
  max-width: 424px;
  width: 100%;
  background-color: #f3f3f3;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.heroButton {
  background-color: transparent;
  color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
}

.heroButton:hover {
  background-color: var(--attentionColorLight);
}

.heroButton:focus {
  background-color: var(--attentionColorLight);
}
