@import '../../styles/marketplace.css';

.listingCard {
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }
}

.listingImage {
  border-radius: 24px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.listingLinkTop {
  position: relative;
  padding-top: 200px;

  &:hover {
    text-decoration: none;
  }
}

.listingContent {
  display: flex;
  padding: 16px 16px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.listingTitle {
  color: #1c1c1c;

  /* ProLed 16 Semibold */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-decoration: none;
  margin: 0;
}

.listingCategory {
  color: #656565;
  text-align: center;

  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
  text-decoration: none;
}

.listingActivities {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px 8px;
  align-self: stretch;
  flex-wrap: wrap;
}

.listingActivity {
  border-radius: 48px;
  background: #dfedf7;
  padding: 4px 16px;
  color: #4c687f;

  /* ProLed 14 Regular */
  font-family: 'sofiapro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  text-transform: capitalize;
}

.listringProfile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;

  &:hover {
    text-decoration: none;
  }
}

.profile {
  display: flex;
  align-items: center;
  gap: 16px;
}

.profileContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.profileTitle {
  color: #1c1c1c;

  /* ProLed 14 Semibold */
  font-family: 'sofiapro';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin: 0;
}

.profileRole {
  color: #656565;

  /* ProLed 14 Regular */
  font-family: 'sofiapro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  text-transform: capitalize;
}
