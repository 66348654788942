
    @apply --marketplaceButtonFontStyles;

    
    display: block;
    width: 100%;
    margin: 0;

    
    display: flex;
    align-items: center;
    justify-content: center;

    
    padding: 8px 24px;

    
    border: none;
    border-radius: 8px;

    
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    
    text-align: center;
    text-decoration: none;

    
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorLight);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }
    &:disabled {
      background-color: var(--matterColorAnti);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      
    }
  