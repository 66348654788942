@import '../../../styles/marketplace.css';

.filterContainer {
  height: fit-content;
}

.filterLabel {
  display: flex;
  padding: 8px 16px 8px 8px;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid #d4d6dd;
  background: #fff;
}

.filterLabel span {
  color: var(--typo-primary, #1c1c1c);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.filterContent {
  width: fit-content;

  border-radius: 12px;
  border: 1px solid #ddd;
  background: #fff;

  /* shadow-s */
  box-shadow: 0px 1px 10px 0px rgba(50, 50, 71, 0.08), 0px 0px 1px 0px rgba(12, 26, 75, 0.24);

  @media (--viewportMedium) {
    width: 480px;
  }
}

.filterTitle {
  padding: 24px 24px 0;
  color: var(--typo-primary, #1c1c1c);

  /* ProLed 16 Semibold */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.filterItemContainer {
  padding: 16px 24px 0px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
}

.filterItem {
  padding: 8px 16px;
  border-radius: 40px;
  border: 1px solid #ddd;
  color: #1c1c1c;
  text-align: center;

  /* ProLed 16 Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  cursor: pointer;
}

.filterItemActive {
  background-color: #313131;
  color: #fff;
}

.filterAction {
  display: flex;
  padding: var(--spacing-3, 16px) var(--spacing-4, 24px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--border-default, #ddd);
  margin-top: 32px;
}

.filterBtnApply {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  color: var(--typo-secondary, #656565);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.filterBtnGray {
  font-size: 16px;
}

.filterSliderValueContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding: 0px 24px;
}

.filterSliderValueContainer span {
  color: var(--typo-primary, #1c1c1c);
  text-align: center;

  /* ProLed 16 Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
