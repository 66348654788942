@import '../../../../styles/marketplace.css';

.root {
  /* height: 200px; */
  /* width: 100px; */
  margin-left: 20px;
  position: relative;
  height: fit-content;
}

.root:nth-of-type(2n) {
  top: 50px;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  /* width: 100%; */
  position: relative;
  /* height: 200px; */
  /* width: 100px; */

  border-radius: 50px;
  overflow: hidden;
  /* transition: var(--transitionStyleButton); */

  /* &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  } */
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  /* paddingBottom: 66.6667%; 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  /* position: relative; */
  /* height: 200px; */
  /* width: 100%; */
  /* width: 100px; */
  /* border-radius: 50px; */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  /* width: 100%; */
  /* width: 100px; */
  /* height: 200px; */
  /* overflow: hidden; */

  border-radius: var(--borderRadius);
  border-radius: 50px;
  /* border-radius: 50%; */
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 5px 2px 2px 2px;

  @media (--viewportMedium) {
    padding: 16px 0 2px 0;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 8px;

  @media (--viewportMedium) {
    margin-right: 18px;
  }
}

.priceValue {
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  font-size: 14px;
  line-height: 16px;

  @media (--viewportMedium) {
    /* Font */
    /* @apply --marketplaceH3FontStyles; */
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--marketplaceColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--matterColorDark);
  font-size: 14px;
  line-height: 16px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Font */
    /* @apply --marketplaceH3FontStyles; */
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorDark);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.ovalInfo {
  /* width: 70px; */
  /* height: 40px; */
  background-color: aliceblue;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  position: relative;
  top: 0px;
  left: 0px;
}

.listingImage {
  width: 100%;
  max-width: 200px;
  /* height: 550px; */
}
