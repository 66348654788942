.tabContainer {
  display: flex;
  align-items: center;
}

.tabTitle {
  margin-left: 16px;
  transition: 0.3s;
}

.hidden {
  opacity: 0;
  width: 0px;
  margin-left: 0px;
}
