@import '../../styles/marketplace.css';

.root {
}

.input {
  /* border-bottom-color: var(--attentionColor); */
  border: 1px solid #ddd;
  border-radius: 8px;
  color: var(--matterColor);

  /* &:hover {
    border-bottom-color: #ddd;
  } */

  /* &:focused {

  } */
}

/* .inputSuccess {
  border-color: var(--successColor);
} */

.inputError {
  border-color: var(--failColor);
}

.textarea {
}
