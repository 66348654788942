@import '../../styles/marketplace.css';

.root {
  @apply --marketplaceButtonStyles;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primaryButtonRoot {
  @apply --marketplaceButtonStylesPrimary;
  padding: 8px 16px;
}

.secondaryButtonRoot {
  @apply --marketplaceButtonStylesSecondary;
}

.inlineTextButtonRoot {
  @apply --marketplaceLinkStyles;
}

.primaryButton {
  /* Class handle for primary button state styles */
}
.secondaryButton {
  /* Class handle for secondary button state styles */
}

.inProgress {
  /* padding: 0; */

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.inProgress:disabled {
  background-color: var(--marketplaceColor);
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  /* padding: 0; */

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  background-color: var(--marketplaceColor);
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

.socialButton {
  background-color: #fff;
  width: 100%;
  padding: 12px 32px;
  font-size: 18px;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}

.grayButton {
  border-radius: 8px;
  background: #313131;
  color: #fff;

  text-align: center;
  /* ProLed 16 Semibold */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  outline: inherit;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}
