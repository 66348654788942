@import '../../../styles/marketplace.css';

.offers_container {
  display: flex;
  justify-content: center;
}

.offers {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  max-width: 1216px;

  @media (--viewportLarge) {
    padding-top: 96px;
  }
}

.offers_title {
  color: #1c1c1c;
  text-align: center;

  /* ProLed 32 Heading */
  font-family: 'Bricolage Grotesque';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 137.5% */
  letter-spacing: -0.64px;
}

.offers_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.offers_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  @media (--viewportLarge) {
    display: flex;
    height: 360px;
    flex-direction: row;
  }
}

.offers_content_item {
  display: flex;
  padding: 32px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  @media (--viewportLarge) {
    flex: 1;
    max-width: 384px;
    justify-content: space-between;
  }
}

.offers_content_item_content {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.offers_content_item_green {
  border-radius: 32px;
  background: #e6f275;
}

.offers_content_item_blue {
  border-radius: 32px;
  background: #0f69e0;
  color: #fff;
}

.offers_content_item_gray {
  border-radius: 32px;
  background: #313131;
}

.offers_content_item img {
  width: 64px;
  height: 64px;
}

.offers_content_item_title {
  color: #1c1c1c;

  /* ProLed 24 Semibold */
  font-family: 'sofiapro';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  margin: 0;
}

.offers_white_color {
  color: #fff !important;
}

.offers_content_item_desc {
  color: #1c1c1c;

  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.offers_content_item_cta {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.offers_content_item_cta span {
  color: #1c1c1c;
  /* ProLed 16 Semibold */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.offers_content_item_cta img {
  width: 20px;
  height: 20px;
}
