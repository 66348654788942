@import '../../../styles/marketplace.css';

.hero {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.hero .title {
  @apply --marketplaceHeroTitleFontStyles;
  color: #1c1c1c;
  margin: 0px;
  position: relative;
}

.hero .title::after {
  content: '';
  background-image: url('../../../assets/becomepro-title.svg');
  position: absolute;
  width: 251px;
  height: 49px;
  bottom: -40px;
  right: -25px;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.hero .desc {
  margin-top: 16px;
  color: var(--typo-primary, #1c1c1c);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: relative;

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 36px;
    max-width: 787px;
  }
}

.hero .desc::after {
  content: '';
  background-image: url('../../../assets/becomepro-desc.svg');
  position: absolute;
  width: 100px;
  height: 100px;
  left: -50px;
  top: 0;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.hero .buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  gap: 8px;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.hero .buttons .explore {
  padding: 8px 16px;
  min-height: auto;
  border: none;
  box-shadow: none;
  flex-basis: content;
}

.hero .buttons .explore:hover {
  border: none;
  box-shadow: none;
}

.hero .contents {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (--viewportLarge) {
    flex-direction: row-reverse;
  }
}

.hero .content {
  background: #dfedf7;
  border-radius: 32px;
  padding: 40px;
  width: 100%;
  height: 362px;
  position: relative;
  overflow: hidden;

  @media (--viewportLarge) {
    max-width: 450px;
    height: 486px;
  }
}

.hero .content img {
  height: 278px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);

  @media (--viewportLarge) {
    height: 400px;
  }
}

.hero .content .text {
  color: #1c1c1c;
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.hero .content .categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 24px;
}

.hero .content .categories .item {
  border-radius: 40px;
  background: #fff;
  padding: 4px 16px;
  font-size: 12px;

  @media (--viewportLarge) {
    font-size: 16px;
  }
}

.hero .content .categories .item_black {
  border-radius: 40px;
  background: #000;
  color: #fff;
  padding: 4px 16px;
  font-size: 12px;

  @media (--viewportLarge) {
    font-size: 16px;
  }
}

.hero .income {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;

  @media (--viewportLarge) {
    width: 280px;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.hero .income .members {
  display: flex;
  margin-bottom: 16px;
}

.hero .income .members img {
  width: 40px;
  height: 40px;

  @media (--viewportLarge) {
    width: 64px;
    height: 64px;
  }
}

.hero .income .text {
  color: #1c1c1c;
  text-align: center;
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.hero .income .members img:not(:first-child) {
  margin-left: -8px;
}

.hero .income .box {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  border-radius: 24px;
  background: #0f69e0;
  width: 250px;

  @media (--viewportLarge) {
    height: 200px;
    width: auto;
  }
}

.hero .income .box .top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.hero .income .box .top .money {
  color: #fff;
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.hero .income .box .top .label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.hero .income .box .top .label span {
  color: #fff;
}

.hero .income .box .bottom {
  width: 100%;
}

.dots {
  display: flex;
  width: 100%;
  align-self: stretch;
  gap: 4px;
}

.dots .dot {
  background: #fff;
  border-radius: 8px;
  height: 4px;
  flex-grow: 1;
  flex-basis: calc(100% / 4);

  @media (--viewportLarge) {
    height: 8px;
  }
}

.dots .dot_gray {
  background: #9fc9ea;
  border-radius: 8px;
  height: 4px;
  flex-grow: 1;
  flex-basis: calc(100% / 4);

  @media (--viewportLarge) {
    height: 8px;
  }
}

.hero .income .box .range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.hero .income .box .range span {
  color: #fff;
  font-family: 'sofiapro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}

.hero .message {
  flex-direction: column;
  display: flex;
  height: 300px;
  padding: 16px 32px 32px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 390px;
  border-radius: var(--radius-2xl, 40px);
  background: #e6f275;
  display: none;

  @media (--viewportLarge) {
    display: flex;
  }
}

.hero .message span {
  color: #1c1c1c;
  text-align: center;
  font-family: 'sofiapro';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}
