@import '../../styles/marketplace.css';

.root {
  font-weight: 16px;
  line-height: 24px;
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  background-color: #fff;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.wrapperMain {
  max-width: 1260px;
  margin: 0 auto;
  padding: 0px;
  background-color: #fff;

  @media (--viewportMedium) {
    padding: 32px;
  }

  @media (--viewportLarge) {
    background-color: #f8f7f5;
  }
}

.sidebarContainer {
  display: flex;
  flex-direction: row;
  background-color: #f8f7f5;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.gallerySection {
  gap: 24px;

  @media (--viewportLarge) {
    display: flex;
  }
}

.galleryBlock,
.infoBlock {
  border-radius: 8px;
  background: #fff;
  width: 100%;
}

.infoBlock {
  padding: 32px 40px;
}

.title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #1c1c1c;
  margin: 0 0 24px 0;
}

.price {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #959595;
  display: flex;
  align-items: center;
}

.amount {
  font-size: 24px;
  line-height: 32px;
  color: #1c1c1c;
}

.aboutInfo {
  margin-top: 24px;
  color: #656565;
  font-size: 16px;
  line-height: 24px;
}

.galleryBlock {
  @media (--viewportLarge) {
    padding: 40px;
  }
}

.contentContainer {
  margin-top: 24px;
}

.authorInfoBLock {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 40px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.authorInfo {
  display: flex;
  align-items: center;
}

.authorName {
  margin-left: 16px;
  font-weight: 600;
}

.messageButton {
  background-color: #f3f3f3;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.desktopGallery {
  display: none;
  @media (--viewportLarge) {
    display: block;
    border-radius: 16px;
    overflow: hidden;
  }
}

.mobileGallery {
  display: block;
  @media (--viewportLarge) {
    display: none;
  }
}

.sectionMap {
  margin-bottom: 35px;
  margin-top: 32px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  /* height: calc(100vh - 193px); */
  width: 100%;
  height: 270px;

  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;

  @media (--viewportLarge) {
    height: 264px;
    width: 318px;
  }
}

.address {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #1c1c1c;

  @media (--viewportLarge) {
    font-size: 18px;
    width: 318px;
  }
}
