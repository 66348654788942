@import '../../styles/marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ProfileSettingsForm_avatarSize: 64px;
  --ProfileSettingsForm_avatarSizeDesktop: 64px;
}

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
}

.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 56px;
  }
}

.inputsContainer {
  width: 100%;

  @media (--viewportMedium) {
    display: flex;
    gap: 44px;
  }
}

.inputsBlock {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.avatarPlaceholder,
.avatarContainer {
  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;
  margin-left: 24px;
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 64px;
  height: 64px;
  display: block;
}

.changeAvatar {
  padding: 8px 16px;
  text-wrap: nowrap;
  border: solid 1px var(--matterColorNegative);
  border-radius: 8px;
  background-color: #f3f3f3;

  @media (--viewportMedium) {
    transition: var(--transitionStyleButton);
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  color: #1c1c1c;
  cursor: pointer;
  color: #ffffff;
  background-color: #0f69e0;
  width: 100%;
  border: none;

  &:disabled {
    background-color: #f3f3f3;
    color: #cccccc;
  }

  @media (--viewportMedium) {
    position: absolute;
    top: 0;
    right: 0;
    width: unset;
  }
}

.input {
  margin-bottom: 20px;
  font-size: 16px;
}

.textarea {
  margin-bottom: 20px;
}
