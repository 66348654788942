@import '../../styles/marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TabNav_linkWidth: 240px;
  --TabNav_linkBorderWidth: 4px;
}

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 24px;
}

.link {
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
  white-space: nowrap;
  transition: 0.2s;

  /* Font */
  /* @apply --marketplaceTabNavFontStyles; */

  color: #656565;
  padding: 10px 12px;

  /* push tabs against bottom of tab bar */
  margin-top: 0;
  margin-bottom: 8px;
  border-radius: 8px;
  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: #1c1c1c;
    background-color: #eff6fb;
  }

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
  }

  cursor: pointer;
}

.selectedLink {
  color: #1c1c1c;
  background-color: #dfedf7;

  &:hover {
    background-color: #dfedf7;
  }

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
  }
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

.avatarContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.role {
  width: 110px;
  margin: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 28px;
}

.navigationContainer {
  padding-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.upButton,
.downButton {
  cursor: pointer;
  display: flex;
}

.buttonIcon {
  height: 14px;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.logoContainer,
.pinIconContainer {
  height: 30px;
  cursor: pointer;
}

.pinIconContainer {
  width: 32px;
  height: 32px;
  background-color: #f3f3f3;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoNameIcon {
  transition: 0.3s;
  margin-left: 9px;
}

.hidden {
  opacity: 0;
}

.logoBarContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoIconContainer {
  display: flex;
  flex-wrap: nowrap;
}
.logoIcon {
  margin-left: 16px;
}

.pinIcon,
.unpinIcon {
  cursor: pointer;
  padding: 6px;
}

.sideNavigationContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 90vh;
}

.logout {
  margin-left: 16px;

  @media (--viewportLarge) {
    margin-left: 0px;
  }
}

/* Switcher */
/*  */
/* .switchRoot {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switchLabel .switchButton {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switchCheckbox:checked + .switchLabel .switchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchLabel:active .switchButton {
  width: 60px;
} */

.switcher {
  width: 232px;
  height: 40px;
  border-radius: 8px;
  background-color: #f6f6f6;
  font-size: 12px;
  padding: 4px;
  transition: 0.3s;
  display: flex;
  gap: 4px;
}

.switcherButton {
  width: 110px;
  height: 32px;
  border-radius: 6px;
  background-color: #f6f6f6;
  border: none;
  cursor: pointer;
  color: #656565;
}

.active {
  background-color: #fff;
  color: #1c1c1c;
}

.linkWrapper {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.becomeProBanner {
  width: 232px;
  height: 100px;
  background-color: rgba(255, 205, 156, 0.2);
  border: 1px solid rgba(215, 135, 85, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  transition: 0.3s;
  text-decoration: none;
  color: #656565;
}

.becomeProHeader {
  font-size: 16px;
  line-height: 24px;
  color: #1c1c1c;
}

.becomeProDescription {
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
}

.bottomSettingsContainer {
  margin-bottom: 48px;
}

.logoutContainer,
.settingsContainer {
  padding: 10px 12px;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
}

.settingsContainer {
  padding: 0;
}

.iconImage {
  margin-right: 16px;
}

.logoutText {
  text-wrap: nowrap;
  &:hover {
    text-decoration: none;
    color: #1c1c1c;
  }
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;
  position: absolute;
  top: 70px;
  left: 55%;
}

.iconWrapper {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.iconWrapper:hover .tooltiptext {
  visibility: visible;
}

.borderLine {
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  margin-top: 8px;
  margin-bottom: 16px;
}
