@import '../../../styles/marketplace.css';

.desktopToolbarContainer {
  display: none;
  justify-content: center;

  /* fill */
  background-color: var(--matterColorLight);

  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopToolbar {
  max-width: 1440px;
  width: 100%;
  display: flex;
  padding: 16px 32px;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1440px) {
    padding: 16px 112px;
  }
}

.logo {
  width: 127px;
  height: auto;
}

.searchContainer {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #ddd;

  @media (min-width: 1440px) {
    width: 300px;
  }
}

.searchContainer form {
  flex: 1;
}

.searchContainer input {
  padding: 0;
  border-bottom: none;
}

.navbar {
  display: flex;
  align-items: center;
  gap: 12px;
}

.navigationLink {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  white-space: nowrap;
  transition: 0.2s;
  font-weight: 600;

  /* Font */
  /* @apply --marketplaceTabNavFontStyles; */

  color: #1c1c1c;
  padding: 10px 12px;

  /* push tabs against bottom of tab bar */
  margin-top: 0;
  transition: var(--transitionStyleButton);
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  cursor: pointer;
}

.navigationLinkActive {
  color: #0f69e0;
}

.signButton {
  font-size: 16px;
}
