@import '../../styles/marketplace.css';

p {
  margin: 0px;
}

.page {
  color: #656565;
  font-size: 16px;
  line-height: 24px;
  background-color: #f8f7f5;
}

.wrapperMain {
  max-width: 1260px;
  margin: 0 auto;
  padding: 0;
  background-color: #f8f7f5;
}

.rootWrapper {
  margin: 32px auto 48px auto;
  background-color: white;
  border-radius: 12px;

  @media (--viewportLarge) {
    margin: 72px auto 160px auto;
  }
}

.sidebarContainer {
  background-color: #f8f7f5;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
  }
}

.container {
  /* max-width: calc(100vw - 64px); */
  padding: 32px;
}

.headerContainer {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.infoBlock {
  display: block;

  @media (--viewportMedium) {
    display: flex;
  }
}

.socialTitle {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.avatarContainer {
  display: flex;
  align-items: start;
  height: 55px;

  @media (--viewportLarge) {
    height: 90px;
  }
}

.blockTitle {
  font-weight: 600px;
  margin-top: 24px;
  margin-bottom: 12px;
  color: #1c1c1c;
  font-size: 18px;
  line-height: 24px;

  @media (--viewportLarge) {
    margin-top: 32px;
    margin-bottom: 16px;
  }
}

.topbar {
  display: block;
  @media (--viewportMedium) {
    display: flex;
  }
}

.buttonsContainer {
  /* width: 100%; */
  display: flex;
  /* flex: 1; */
  justify-content: space-between;
  gap: 12px;
  height: 44px;
}

.buttonContainer,
.bookmarkButtonContainer {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  background-color: #f3f3f3;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  color: #1c1c1c;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding: 8px 16px;
    border-radius: 6px;
    height: 44px;
  }
}

.buttonLink {
  &:hover {
    text-decoration: none;
  }
}

.bookmarkButtonContainer {
  padding: 4px;

  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    padding: 8px;
    width: 44px;
  }
}

.socialLinks {
  display: flex;
  /* flex: 1; */
  /* justify-content: space-between; */
}

.aboutContainer,
.socialLinksContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.aboutContainer {
  @media (--viewportMedium) {
    max-width: 600px;
    margin-right: 64px;
  }
}

.categoriesContent,
.typesContent {
  /* width: 100%; */
  display: flex;
  overflow-x: scroll;

  @media (--viewportMedium) {
    overflow-x: hidden;
  }
}

.typesContent {
  margin-top: 56px;
  margin-bottom: 24px;
}

.type {
  padding: 8px 16px;
  border-radius: 8px;
  color: #1c1c1c;
  cursor: pointer;
  margin-right: 16px;
}

.type.active {
  background-color: #f3f3f3;
}

.categorie {
  padding: 8px 16px;
  border-radius: 36px;
  background-color: #f3f3f3;
  display: flex;
  /* gap: 12px; */
  margin-right: 12px;
  color: #656565;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.horizontalLine {
  width: 100%;
  height: 1px;
  border: 1px solid #e9e9e9;
  margin-bottom: 32px;
}

.tabMenu {
  display: flex;
}

.socialLinksColumn {
  display: flex;
  flex: 1;
  justify-content: space-around;

  @media (--viewportMedium) {
    display: block;
  }
}

.socialLink {
  display: flex;
  cursor: pointer;
  color: #1c1c1c;

  @media (--viewportMedium) {
    margin-bottom: 20px;
    margin-right: 84px;
    max-width: max-content;
  }
}

.socialIcon {
  margin-right: 0px;

  @media (--viewportMedium) {
    margin-right: 12px;
  }
}

.listingPanel {
  /* margin-top: 32px; */
}

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.aside {
  box-shadow: none;
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
  }
}

.avatar {
  margin: 30px 26px 0 0;
  flex-shrink: 0;
  position: relative;
  width: 100px;
  height: 100px;
  top: -76px;

  @media (--viewportLarge) {
    width: 150px;
    height: 150px;
    margin: 0 28px 0 0;
    top: -60px;
  }
}

.mobileHeading {
  flex-shrink: 0;
  margin: 47px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.desktopHeading {
  /* display: none; */
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin: 0px;
  font-weight: 600;
  color: #313131;
  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 38px;
  }
}

.subTitle {
  font-size: 16px;
  line-height: 24px;

  @media (--viewportLarge) {
    font-size: 18px;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  @apply --clearfix;
  border-top: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 58px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;
  &:first-of-type {
    margin-top: 18px;
  }

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(50% - 12px);
    margin-bottom: 36px;

    /* Horizontal space */
    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    }

    /* Vertical space */
    margin-top: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    max-width: 600px;
    margin-bottom: 0;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH3FontStyles;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.searchButton {
  @apply --marketplaceButtonStyles;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
}

.listItem {
  margin: 30px 0;
}

.editLinkDesktop {
  /* @apply --marketplaceLinkStyles; */
  margin: 10px 0;
  cursor: pointer;
  color: black;
}

.editLinkDesktop:hover {
  text-decoration: none;
}

/* .active {
  color: var(--marketplaceColorLight);
  text-decoration: underline;
} */

.listingCards {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;

  @media (--viewportMedium) {
    justify-content: start;
  }
}

.listingCard {
  width: 100%;
  /* @media (--viewportMedium) { */
  width: 280px;
  /* } */
}

.addListingCard {
  width: 280px;
  height: 178px;
  padding: 45px 50px;
  border-radius: 16px;
  border: 1px solid #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.addListingCardDescription {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #656565;
}

.addButton {
  /* padding: 4px 12px; */
  background-color: #0f69e0;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  border: none;
  margin-top: 16px;
  width: 120px;
  height: 28px;
  cursor: pointer;
}

.showMoreButton {
  cursor: pointer;
  color: #0f69e0;
  font-weight: 600;
}

.desktopAbout {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.mobileAbout {
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}
