@import '../../../styles/marketplace.css';

.flow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flow_1 {
  padding: 80px 24px 40px 24px;

  @media (--viewportLarge) {
    padding: 96px 24px 40px 24px;
  }
}

.flow_2 {
  padding: 40px 32px;
}

.flow_3 {
  padding: 40px 32px 80px 32px;

  @media (--viewportLarge) {
    padding: 40px 32px 96px 32px;
  }
}

.flow_container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--viewportLarge) {
    padding-left: 45px;
    flex-direction: row;
    align-items: center;
    gap: 96px;
  }
}

.flow_container_reverse {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--viewportLarge) {
    flex-direction: row-reverse;
    align-items: center;
    gap: 96px;
  }
}

.flow_number {
  color: #d5f456;
  font-family: 'Bricolage Grotesque';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
}

.flow_title {
  color: #1c1c1c;
  font-family: 'Bricolage Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin: 0;
  margin-top: 24px;
  margin-bottom: 16px;
}

.flow_desc {
  margin: 0;
  color: #1c1c1c;

  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  @media (--viewportLarge) {
    width: 300px;
  }
}

.flow_img {
  margin-top: 96px;
  width: 241px;
  align-self: center;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}
