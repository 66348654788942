@import '../../../styles/marketplace.css';

.features_container {
  display: flex;
  justify-content: center;
}

.features {
  display: flex;
  padding: 40px 24px 80px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  overflow: hidden;
  width: 100vw;

  @media (--viewportLarge) {
    width: 1216px;
  }
}

.features_title {
  color: #1c1c1c;
  text-align: center;
  font-family: 'Bricolage Grotesque';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.64px;
  margin: 0;
}

.features_tabs {
  display: flex;
  overflow: auto;
  gap: 8px;
  width: 100%;

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.features_tab {
  padding: 8px 24px;
  cursor: pointer;
}

.features_tab_active {
  border-radius: 8px;
  background: #f3f3f3;
}

.features_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;

  @media (--viewportLarge) {
    align-items: flex-start;
    flex-direction: row;
    align-self: flex-start;
    gap: 112px;
  }
}

.features_content img {
  width: 100%;

  @media (--viewportLarge) {
    width: 622px;
  }
}

.features_content_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  @media (--viewportLarge) {
    padding: 40px 0px;
    max-width: 300px;
  }
}

.features_content_title {
  color: #1c1c1c;
  /* ProLed 24 Semibold */
  font-family: 'sofiapro';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  margin: 0;
}

.features_content_title span {
  margin-left: 32px;
  border-radius: 40px;
  background: #dfedf7;
  backdrop-filter: blur(2px);
  padding: 4px 16px;

  color: #4c687f;

  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.features_content_desc {
  color: #1c1c1c;
  /* ProLed 16 Regular */
  font-family: 'sofiapro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
