
    font-weight: var(--fontWeightBold);
    text-align: center;
    font-family: 'Bricolage Grotesque';
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.96px;

    @media (--viewportMedium) {
      text-align: center;
      font-family: 'Bricolage Grotesque';
      font-size: 72px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -1.44px;
    }

    @media (min-width: 1681px) {
      font-size: 90px;
      line-height: 96px;
      letter-spacing: -2px;
    }
  